import { useState } from 'react';

import { FormControl, FormErrorMessage } from '@chakra-ui/form-control';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { removeMyWAuthTokens, setWimbTvSession } from '_customSites/wimb/services/wimbLocalStorage';
import { authenticationService } from 'authentication/services/authenticationService';
import { Formik, Form } from 'formik';
import { useMutation } from 'react-query';

import customSitesConfig from '_shared/config/customSitesConfig';
import { IMAGE_TV_PORTAL, IMAGE_ATP_PORTALS } from '_shared/constants/imageConsts';
import { Box, Input, Flex, Button, ErrorMessage, Center, Text, Image } from '_shared/designSystem/components';
import { logBasicMessage } from '_shared/errors/log';
import { isWimbPortal, isTvPortal } from '_shared/utils/environment/currentEnv';
import { setLocalStorageAuthTokens } from '_shared/utils/localStorage';

export default function Login() {
  const [formSubmitError, setFormSubmitError] = useState(null);
  const [formUserName, setFormUserName] = useState(null);
  const navigate = useNavigate();

  const { r: redirectUrl } = useSearch();
  const formSubmitErrorMessage = 'Your username and password were not recognised, please try again';

  const mutation = useMutation((credentials) => authenticationService.validateCredentialsToken({ credentials }), {
    onSuccess: ({ access_token, refresh_token }) => {
      if (access_token) {
        if (isWimbPortal()) {
          setWimbTvSession();
          removeMyWAuthTokens();
        }
        setLocalStorageAuthTokens(access_token, refresh_token, formUserName);
        logBasicMessage('INFO', `User ${formUserName} signed in`);

        // if there is a redirect url, navigate to it, otherwise navigate to the default for the custom site
        if (!redirectUrl) {
          navigate({ to: customSitesConfig.redirectAfterLogin });
          return;
        }
        const redirect = atob(redirectUrl);
        if (redirect !== '/login') {
          navigate({ to: redirect });
          return;
        }
        navigate({ to: customSitesConfig.redirectAfterLogin });
      } else {
        setFormSubmitError(formSubmitErrorMessage);
      }
    },
    onError: () => setFormSubmitError(formSubmitErrorMessage)
  });

  // These styles relate to this page only and are not shared within the design system's Input component
  const sharedInputStyles = {
    h: '44px',
    borderRadius: 'sm',
    size: 'sm',
    py: 6,
    textAlign: 'center',
    focusBorderColor: 'primary.500',
    bg: 'gray.100',
    _focus: { bg: 'white', boxShadow: 'md' },
    w: { base: '300px', md: '360px' }
  };

  return (
    <Flex height="100vh">
      {!isWimbPortal() && (
        <Box flex="1" position="relative" display={{ base: 'none', md: 'block' }}>
          <Image
            src={isTvPortal() ? IMAGE_TV_PORTAL : IMAGE_ATP_PORTALS}
            objectFit="cover"
            height="100%"
            width="100%"
          />
          <Box position="absolute" top="0" left="0" right="0" bottom="0" bg="secondary.400" opacity="0.8" />
        </Box>
      )}
      <Box flex="1" display="flex" alignItems="center" justifyContent="center">
        <Formik
          initialValues={{ userName: '', password: '' }}
          validate={(values) => {
            setFormSubmitError(null);
            setFormUserName(values.userName);
            const errors = {};
            if (!values.userName) {
              errors.userName = 'Please enter your username';
            }
            if (!values.password) {
              errors.password = 'Please enter your password';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            mutation.mutate(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, values, errors, touched, handleChange, handleBlur }) => (
            <Form>
              <Box w={{ base: '300px', md: '360px' }}>
                <Flex direction="column" gap="20px">
                  <Center>{customSitesConfig.loginLogoComponent}</Center>
                  <Center>
                    <Text fontSize="sm" fontWeight="medium" color="grey.600">
                      Please sign in to continue.
                    </Text>
                  </Center>
                  <FormControl isInvalid={errors.userName && touched.userName}>
                    <Input
                      id="userName"
                      name="userName"
                      placeholder="Enter username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.userName}
                      type="text"
                      {...sharedInputStyles}
                    />
                    <FormErrorMessage>{errors.userName}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.password && touched.password}>
                    <Input
                      id="password"
                      name="password"
                      placeholder="Enter password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      type="password"
                      {...sharedInputStyles}
                    />
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  </FormControl>
                  {formSubmitError && <ErrorMessage message={formSubmitError} />}
                  <Button
                    spinnerPlacement="start"
                    type="submit"
                    disabled={isSubmitting}
                    bg="secondary.400"
                    color="white"
                    py={4}
                    height="auto"
                    w={{ base: '300px', md: '360px' }}
                    _hover={{ bg: 'secondary.600' }}
                    fontSize="xl"
                  >
                    Login
                  </Button>
                </Flex>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
}

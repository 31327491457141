import { useEffect, useMemo, useState } from 'react';

import { useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import isEmpty from 'lodash/isEmpty';
import { AblyUpdater } from 'match/components/_shared/AblyUpdater';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import ScheduleHeader from 'schedule/components/ScheduleHeader';
import { viewingTypes } from 'schedule/constants/scheduleConstants';
import { getDays, getScheduleRoundsData, setFormStateAndNavigate } from 'schedule/util/courtViewUtil';
import { getFormStateAndViewingTypeFromUrl } from 'schedule/util/scheduleUtil';
import { performAllTournamentSearch } from 'schedule/util/tournamentUtil';
import { HeaderWrapper, SearchWrapper } from 'tournaments/components/_shared/Headers';
import { useTournamentQuery } from 'tournaments/hooks/tournamentsHooks';

import {
  ErrorMessage,
  Flex,
  Grid,
  GridItem,
  LoadingSpinner,
  useBreakpointValue
} from '_shared/designSystem/components';
import ScrollToTopArrow from '_shared/designSystem/components/icon/ScrollToTopArrow';
import { liveMatchRefetchIntervalState, mobileHeaderTextState } from '_shared/globalState/atoms';
import { searchByString } from '_shared/utils/stringUtil';

import MatchCard from './MatchCard';
import ScheduleCourtView from './ScheduleCourtView';
import ScheduleSearch from './ScheduleSearch';

export default function Schedule() {
  const {
    params: { tournamentId }
  } = useMatch();

  const search = useSearch();
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    round: 'All',
    playerName: '',
    day: null
  });
  const [viewingType, setViewingType] = useState(null);
  const liveMatchRefetchInterval = useRecoilValue(liveMatchRefetchIntervalState);
  const { data, isLoading, error, refetch } = useTournamentQuery(tournamentId, {
    refetchInterval: liveMatchRefetchInterval
  });
  const setMobileHeaderText = useSetRecoilState(mobileHeaderTextState);

  useEffect(() => {
    if (!isEmpty(data?.name)) {
      setMobileHeaderText(data?.name);
    }
  }, [setMobileHeaderText, data?.name]);

  useEffect(() => {
    const { newFormState, viewingType } = getFormStateAndViewingTypeFromUrl(
      search,
      data?.comp_status,
      data?.active_day
    );
    setFormState(newFormState);
    setViewingType(viewingType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const listViewSearchResults = useMemo(() => {
    return performAllTournamentSearch(data?.matches, formState.round, formState.playerName);
  }, [data, formState.round, formState.playerName]);

  const courtViewSearchResults = useMemo(() => {
    return searchByString(data?.matches, formState.playerName, 'players');
  }, [data, formState.playerName]);

  const handleFormStateUpdate = (param, value) => {
    setFormStateAndNavigate(formState, setFormState, param, value, navigate, setViewingType);
  };

  const handleWimbledonDayClick = () => {
    const newFormState = { ...formState };
    newFormState.round = null;
    newFormState.day = data?.active_day;
    setViewingType(viewingTypes.COURT_VIEW);
    setFormState({ ...newFormState });
    navigate({
      search: () => ({ ...newFormState })
    });
  };

  if (error) return <ErrorMessage message="Unable to retrieve tournament, please try refreshing the page." />;

  if (isLoading) return <LoadingSpinner />;

  return (
    <>
      {data?.matches.map((item) => (
        <AblyUpdater
          key={item.match_id}
          matchStatus={item.match_status}
          matchId={item.match_id}
          updateDataFunction={refetch}
        />
      ))}
      <Flex direction="column" gap={4}>
        <HeaderWrapper>
          <ScheduleHeader data={data} />
        </HeaderWrapper>
        <SearchWrapper>
          <ScheduleSearch
            rounds={getScheduleRoundsData(data)}
            days={getDays(data.matches)}
            formState={formState}
            handleFormStateUpdate={handleFormStateUpdate}
            handleWimbledonDayClick={handleWimbledonDayClick}
            viewingType={viewingType}
            tournamentId={data?.tournament_id}
          />
        </SearchWrapper>
        {viewingType === viewingTypes.LIST_VIEW ? (
          <ScheduleListView data={listViewSearchResults} />
        ) : (
          <ScheduleCourtView data={courtViewSearchResults} activeDay={formState.day} />
        )}
      </Flex>
    </>
  );
}

export const ScheduleListView = ({ data }) => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false
  });
  return (
    <>
      <Grid pt={3} templateColumns="repeat(auto-fit, minmax(340px, 1fr))" gap={8}>
        {data.map((item) => (
          <GridItem key={item.match_id} align={isMobile ? 'center' : 'initial'}>
            <MatchCard data={item} showTournamentInfo={false} />
          </GridItem>
        ))}
      </Grid>
      <ScrollToTopArrow />
    </>
  );
};

export const courtGraphicTypes = {
  SERVE_DIRECTION: 'serve_direction',
  RETURN_CONTACT_POINT: 'return_contact_point',
  RETURN_PLACEMENT: 'return_placement',
  SERVE_PLUS_ONE: 'serve_plus_one',
  RALLY_CONTACT_POINT: 'rally_contact_point',
  RALLY_PLACEMENT: 'rally_placement'
};

export const DATE_INCOMPLETE_ALERT_TYPES = {
  SHOW_DATA: 'show_data',
  HIDE_DATA: 'hide_data'
};

export const BREAKDOWN_METRICS_TYPES = {
  SERVE_EFFECTIVENESS: 'serve_effectiveness',
  SHOT_VARIATION: 'shot_variation'
};

export const PERFORMANCE_METRICS_TYPES = {
  BALANCE_OF_POWER: 'balance_of_power',
  PERFORMANCE_GRAPH: 'performance_graph',
  SHOT_VARIATION: 'shot_variation',
  SERVE_EFFECTIVENESS: 'serve_effectiveness',
  DATA_COMPLETENESS: 'data_completeness',
  MATCH_STATISTICS: 'match_statistics',
  PERFORMANCE_RATING: 'performance_rating',
  ATTACK_DEFENCE: 'attack_defence',
  SHOT_QUALITY: 'shot_quality',
  SERVE_RETURN_GAME_PERFORMANCE: 'serve_return_game_performance',
  BREAKDOWN_METRICS: 'breakdown_metrics',
  BASELINE_BATTLES: 'baseline_battles',
  MOMENTUM: 'momentum',
  WIN_PROBABILITY: 'win_probability'
};

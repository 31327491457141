import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { displayScore } from 'match/utils/transformations';

import { Flex, Box, VStack, Text, CourtBadge as Badge } from '_shared/designSystem/components';
import { capitaliseString } from '_shared/utils/stringUtil';

import { BarColor, SingleStackedBarProps, StackedAveragesProps, StackedBarSectionProps, DataItem } from './types';

export const defaultColors: BarColor[] = [
  { bg: 'primary.25', text: 'secondary.800' },
  { bg: 'primary.100', text: 'secondary.800' },
  { bg: 'primary.300', text: 'secondary.800' },
  { bg: 'primary.500', text: 'secondary.800' },
  { bg: 'primary.600', text: 'white' },
  { bg: 'primary.700', text: 'white' },
  { bg: 'primary.900', text: 'white' }
];

const SingleStackedBar: React.FC<SingleStackedBarProps> = ({
  dataNoAverages,
  dataWithAverages,
  player,
  showAverages,
  averagesLoading,
  customStackColors
}) => {
  const barColors = customStackColors || defaultColors;
  const columnHeight = 500;

  const showAveragesInBar = false;

  const StackedAverages: React.FC<StackedAveragesProps> = ({ data, player }) => {
    const playerData = data[player];
    return (
      <Flex direction="column" justifyContent="center" alignItems="center">
        <Text color="black" fontWeight="semibold" fontSize="sm" textAlign="center">
          {capitaliseString(data.id)}
        </Text>
        <Box width="50%" mb={1} mt={1}>
          <Badge text={displayScore(playerData.score, playerData.score_type)} />
        </Box>
        <Box width="50%">
          {playerData.player_average && (
            <Box mb={1} data-testid={`${data.id}-player-average`}>
              <Badge text={displayScore(playerData.player_average, playerData.score_type)} />
            </Box>
          )}
          {playerData.tour_average && (
            <Box mb={1} data-testid={`${data.id}-tour-average`}>
              <Badge text={displayScore(playerData.tour_average, playerData.score_type)} />
            </Box>
          )}
        </Box>
      </Flex>
    );
  };

  const StackedBarSection: React.FC<StackedBarSectionProps> = ({ data, index, player }) => {
    if (isEmpty(data[player])) return null;
    const bgColor = barColors[index % barColors.length].bg;
    const textColor = barColors[index % barColors.length].text;
    const height = getHeight(dataNoAverages, player, parseFloat(data[player].score.toString()));

    const createLabel = (id: string) => {
      return id === 'lobs_moonballs' ? 'Lobs / Moonballs' : capitaliseString(id);
    };

    return (
      <Box key={index} height={height} width="100%" backgroundColor={bgColor}>
        <Flex direction="column" justify="center" alignItems="center" height="100%">
          <Text color={textColor} fontWeight="semibold" fontSize="xs" textAlign="center" lineHeight="16px">
            {createLabel(data.id)}
          </Text>
          <Flex gap={1}>
            <Text color={textColor} fontSize="xs">
              {displayScore(data[player].score, data[player].score_type)}
            </Text>
            <Text color={textColor} fontSize="xs">{`(${data[player].frequency})`}</Text>
          </Flex>
        </Flex>
      </Box>
    );
  };

  return (
    <>
      {showAverages && showAveragesInBar ? (
        <Flex direction="column" width="110px" gap={1}>
          {dataWithAverages?.map((data, index) => (
            <StackedAverages key={index} data={data} player={player} />
          ))}
        </Flex>
      ) : (
        <VStack height={`${columnHeight}px`} width="110px" spacing={0}>
          {dataNoAverages?.map((data, index) => (
            <StackedBarSection key={index} data={data} index={index} player={player} />
          ))}
        </VStack>
      )}
    </>
  );
};

export default SingleStackedBar;

export function getHeight(data: DataItem[], player: 'player1' | 'player2', score: number): string {
  if (isEmpty(data)) return '0';
  if (data.every((item) => item[player].score === 0)) return `${100 / data.length}%`;
  else return score < 10 ? '45px' : `${score}%`;
}

import { Flex, HorizontalBar, Text } from '_shared/designSystem/components';
import { capitaliseFirstLetterOfString } from '_shared/utils/stringUtil';

import { leftColumnContentWidth } from '../performanceMargins';

const WinProbabilitySection = ({ win_probability = {}, player1Name, player2Name }) => {
  const probabilities = [{ key: 'match' }, { key: 'set' }, { key: 'game' }];

  const renderProbabilityBars = () =>
    probabilities.map(({ key }) => (
      <HorizontalBar
        key={key}
        label={win_probability?.[key]?.label || `${capitaliseFirstLetterOfString(key)} Win Probability`}
        frequency={win_probability[key]}
        leftPercentage={win_probability?.[key]?.player1?.score || 0}
        rightPercentage={win_probability?.[key]?.player2?.score || 0}
        greaterColor="secondary.600"
        lessColor="secondary.100"
        textColor="white"
      />
    ));

  return (
    <Flex direction="column" gap={4} w={{ base: '100%', sm: `${leftColumnContentWidth}px` }}>
      <Text fontSize="md" fontWeight="semibold" textAlign="center">
        Win Probability
      </Text>
      <Flex justify="space-between" w="100%">
        <Text fontSize="sm" fontWeight="semibold">
          {player1Name}
        </Text>
        <Text fontSize="sm" fontWeight="semibold">
          {player2Name}
        </Text>
      </Flex>
      {renderProbabilityBars()}
    </Flex>
  );
};

export default WinProbabilitySection;

import { useEffect, useState } from 'react';

import { useNavigate, useSearch } from '@tanstack/react-location';
import {
  matchHeaderHeight,
  mobileHeaderHeight,
  topMenuMobileHeight,
  topMenuHeight,
  mobileMatchHeaderHeight
} from '_layouts/margins';
import isEmpty from 'lodash/isEmpty';
import { useWinningAndLosingPlaysSettings } from 'match/hooks/winningAndLosingPlaysHooks';
import { getCourtLegend } from 'match/utils/winningAndLosingPlaysUtil';
import PropTypes from 'prop-types';
import { getScoutCourtLegend } from 'scout/util/scoutWinningAndLosingPlaysUtil';

import SinglePlayGrid from '_shared/components/SinglePlayGrid';
import { Alert, Box, Legend, useBreakpointValue } from '_shared/designSystem/components';
import { scrollToOffset } from '_shared/utils/scrollUtils';
import { removeFieldFromUrl, updateUrl } from '_shared/utils/urlUtil';

import PlaysFilters from './PlaysFilters';
import SinglePlayDetailedView from './SinglePlayDetailedView';

const PlaysSummaryV2 = ({
  playDataNoAverages,
  playDataWithAverages,
  showAverages,
  isLoading,
  averagesLoading,
  surface,
  volumeType,
  isScout,
  playerType,
  playerData,
  player
}) => {
  const initialValues = {
    player: 'player1',
    playType: 'all',
    set: 'all',
    volumeType,
    showAverages,
    playerType
  };

  const scrollName = 'winningAndLosingPlaysV2ScrollPosition';

  const navigate = useNavigate();
  const search = useSearch();

  const [detailedViewShowing, setDetailedViewShowing] = useState(false);
  const [playId, setPlayId] = useState(null);

  const detailedViewTopScrollDepth = useBreakpointValue({ base: mobileMatchHeaderHeight, md: 0 });

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (search.playId) {
      setPlayId(search.playId);
      setDetailedViewShowing(true);
    }
  }, [search.playId]);

  const {
    state: playerSettings,
    handlers: { handleButtonClick, handleAveragesToggle }
  } = useWinningAndLosingPlaysSettings(initialValues);

  const handleSinglePlayClick = (playId) => {
    setPlayId(playId);
    setDetailedViewShowing(true);
    updateUrl('playId', playId, navigate);
    sessionStorage.setItem(scrollName, window.scrollY);
    scrollToOffset(detailedViewTopScrollDepth);
  };

  const handleSinglePlayClose = () => {
    setPlayId(null);
    setDetailedViewShowing(false);
    removeFieldFromUrl('playId', navigate);
    scrollToSummaryLocation();
  };

  const scrollToSummaryLocation = () => {
    const scrollPosition = sessionStorage.getItem(scrollName);
    if (scrollPosition) {
      scrollToOffset(scrollPosition);
      sessionStorage.removeItem(scrollName);
    }
  };

  return (
    <Box pb={5} minWidth="350px" maxW="1700px">
      <Box
        position={{ base: 'sticky' }}
        bg="white"
        top={{
          base: `${mobileHeaderHeight}px`,
          md: !isScout ? topMenuMobileHeight + matchHeaderHeight : topMenuHeight,
          lg: !isScout ? matchHeaderHeight : topMenuHeight
        }}
        transition="top 0.3s ease-in-out"
        zIndex={1}
        px={{ base: 2, lg: 0 }}
      >
        <PlaysFilters
          data={playerData}
          player={playerSettings.player}
          set={playerSettings.set}
          volumeType={playerSettings.volumeType}
          showAverages={playerSettings.showAverages}
          handleButtonClick={handleButtonClick}
          handleAveragesToggle={handleAveragesToggle}
          isScout={isScout}
          playerType={playerSettings.playerType}
        />
      </Box>
      {isEmpty(playDataNoAverages) && !isLoading ? (
        <Box mt={5} px={{ base: 2, lg: 0 }}>
          <Alert message="No Winning and Losing Plays data to show" status="info" />
        </Box>
      ) : detailedViewShowing ? (
        <Box>
          <SinglePlayDetailedView
            dataNoAverages={playDataNoAverages?.find((item) => item.play_id === playId)}
            dataWithAverages={playDataWithAverages?.find((item) => item.play_id === playId)}
            showAverages={showAverages}
            isLoading={isLoading}
            averagesLoading={averagesLoading}
            surface={surface}
            volumeType={volumeType}
            handleClose={handleSinglePlayClose}
            isMatchPage={!isScout}
            player={player}
          />
        </Box>
      ) : (
        <>
          {isMobile && !showAverages && !isScout && (
            <Box mb={4}>
              <Legend direction="horizontal" fields={isScout ? getScoutCourtLegend('all') : getCourtLegend('all')} />
            </Box>
          )}
          <SinglePlayGrid
            playDataNoAverages={playDataNoAverages}
            playDataWithAverages={playDataWithAverages}
            showAverages={showAverages}
            isLoading={isLoading}
            averagesLoading={averagesLoading}
            surface={surface}
            volumeType={volumeType}
            isMatchPage={!isScout}
            playerType
            handleSinglePlayClick={handleSinglePlayClick}
            selectedPlayType={playerSettings.playType}
            playerData={playerData}
            player={player}
          />
        </>
      )}
    </Box>
  );
};

PlaysSummaryV2.propTypes = {
  playDataNoAverages: PropTypes.arrayOf(PropTypes.object),
  playDataWithAverages: PropTypes.arrayOf(PropTypes.object),
  showAverages: PropTypes.bool,
  isLoading: PropTypes.bool,
  averagesLoading: PropTypes.bool,
  surface: PropTypes.string,
  volumeType: PropTypes.string,
  isMatchPage: PropTypes.bool,
  playerData: PropTypes.object,
  player: PropTypes.string
};

PlaysSummaryV2.defaultProps = {
  playDataNoAverages: [],
  playDataWithAverages: [],
};

export default PlaysSummaryV2;

import SinglePlay from 'match/components/winning-and-losing-plays/SinglePlay';
import { convertVolumeType } from 'match/utils/winningAndLosingPlaysUtil';
import { useMatchReportWinningAndLosingPlaysQueryV2 } from 'matchReport/hooks/matchReportHooks';
import PropTypes from 'prop-types';

import { Box, Flex } from '_shared/designSystem/components';
import { capitaliseString } from '_shared/utils/stringUtil';

import PageHeading from '../_shared/PageHeading';

const WinningAndLosingPlaysV2 = ({ matchId, playerName, player, surface, volumeType, isMatch }) => {
  const queryResultPlayer1 = useMatchReportWinningAndLosingPlaysQueryV2(matchId, { player: 'player1' });
  const queryResultPlayer2 = useMatchReportWinningAndLosingPlaysQueryV2(matchId, { player: 'player2' });

  if (queryResultPlayer1.error) throw new Error('Unable to retrieve winning and losing plays data');
  if (queryResultPlayer1.isLoading) return null;
  if (!queryResultPlayer1.data) return null;

  const playerData = player === 'player1' ? queryResultPlayer1.data : queryResultPlayer2.data;
  return (
    <Box>
      <PageHeading>
        {playerName} {capitaliseString(convertVolumeType(volumeType))} of Plays
      </PageHeading>
      <Flex flexWrap="wrap" gap={5} justify="center" rowGap="60px">
        {playerData?.slice(0, 8).map((item) => (
          <Box w="235px" key={item.play_id}>
            <SinglePlay
              dataNoAverages={item}
              dataWithAverages={item}
              surface={surface}
              volumeType={volumeType}
              isMatchPage={isMatch}
            />
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

WinningAndLosingPlaysV2.propTypes = {
  matchId: PropTypes.string.isRequired,
  playerName: PropTypes.string.isRequired,
  player: PropTypes.string.isRequired,
  surface: PropTypes.string.isRequired,
  volumeType: PropTypes.string.isRequired,
  isMatch: PropTypes.bool
};

export default WinningAndLosingPlaysV2;
